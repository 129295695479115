import { Col, Container, Row } from "react-bootstrap";
import React, { useEffect, useState } from "react";
// import handleViewport from "react-in-viewport";
import Image from "next/image";
import { parseHtml, toWebp } from "../../../utils/common";
import styles from "./style.module.scss";

const NextProject = (props) => {
  const baseUrl = process.env.NEXT_PUBLIC_REACT_APP_RESOURCE_BASE_URL;

  const { data } = props;
  const inViewport = true;
  // const { forwardedRef, inViewport, data } = props;

  const [height, setHeight] = useState();

  useEffect(() => {
    const setView = () => {
      if (window?.innerWidth > 1400) {
        setHeight(921);
      } else {
        setHeight(600);
      }
    };
    setView();

    window.addEventListener("resize", setView);
    return () => {
      window.addEventListener("resize", setView);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <section /* ref={forwardedRef} */ className={`common-btn-style ${styles["next-project-section"]}`} style={{ minHeight: `${height}px` }}>
      {inViewport && (
        <Container className={styles['container']}>
          <Row>
            <Col xl={3} lg={4} md={4}>
              <div className={styles["next-project-content"]}>
                <h2>{data.Title}</h2>
                <p>{data.Description}</p>
                <a href={data.Button.href} className="btn btn-primary">
                  {data.Button.label}
                </a>
              </div>
            </Col>
            <Col xl={9} lg={8} md={8}>
              {data?.Card &&
                data?.Card.map((val, i) => {
                  return (
                    <div key={i} className={styles["next-project-card"]}>
                      <div className={styles["next-project-left-content"]}>
                        <div className={styles["next-project-profile"]}>
                          <Image
                            src={
                              val?.image?.data?.attributes?.url
                                ? toWebp(
                                    `${baseUrl}${val?.image?.data?.attributes?.url}`
                                  )
                                : null
                            }
                            alt={
                              val?.image?.data?.attributes?.name || "Project"
                            }
                            height={40}
                            width={40}
                          />
                        </div>
                        <span></span>
                      </div>
                      <div className={styles["next-project-right-content"]}>
                        <h3>{val.display_title} </h3>
                        {val.text ? parseHtml(val.text, true) : ""}
                      </div>
                    </div>
                  );
                })}
            </Col>
          </Row>
        </Container>
      )}
    </section>
  );
};

// const NextProjectViewportBlock = handleViewport(NextProject);

export default NextProject;
